import { ReactNode, useContext } from "react";

import { MapPageContext } from "components/reusables/MapPage";

import { getCarbonDataCharts } from "utils/apiRequests";
import { useQuery } from "react-query";
import SplineChart from "components/charts/SplineChart";

import { Box, Typography } from "@mui/material";

interface PropsI {
  countryCode: string;
  countryName: string;
}

const TimeseriesChart = (props: PropsI) => {
  const countryCode = props.countryCode;
  const countryName = props.countryName;
  const { selectedLayers } = useContext(MapPageContext);

  const category = selectedLayers && selectedLayers[0].id !== undefined ? selectedLayers[0].id : "LULUCF";
  const categoryName = selectedLayers && selectedLayers[0].title !== undefined ? selectedLayers[0].title : "LULUCF";

  const queryOptions = {
    queryKey: ["getCarbonDataCharts", category, countryCode],
    queryFn: getCarbonDataCharts,
    staleTime: Infinity,
    enabled: !!category && !!countryCode
  };

  const { data, isLoading, error } = useQuery(queryOptions);

  if (!selectedLayers || selectedLayers.length == 0 || !countryCode || countryCode === "") return <></>;

  if (!data || data.length === 0) return null;

  const highChartSeries: any[] = data.highchartseries.map((e: any) => {
    e.showInLegend = e.data !== null && e.data.find((ele: any) => ele.y !== null) ? true : false;
    return e;
  });

  const allYears: string[] = data.allYears;

  let hasData = allYears !== undefined && allYears !== null;
  //No data
  if (hasData)
    hasData = highChartSeries
      .map((element: any, index) => {
        return element.data?.find((ele: any) => ele.y !== null) ? true : false;
      })
      .find((ele) => ele)
      ? true
      : false;

  highChartSeries.map(function (series) {
    if (series.name.startsWith("Global Forest Watch")) series.color = "#544fc5";
  });
  const colors: string[] = ["#fe6a35", "#00e272", "#544fc5", "#544fc5"];
  if (!hasData) {
    return (
      <Typography style={{ marginLeft: "5px", marginRight: "5px" }}>
        No Timeseries {categoryName} data was found for {countryName}.{" "}
      </Typography>
    );
  }
  const unit = highChartSeries[0].data.at(0)?.unit || "";
  const titleY = " (" + unit + ")";

  const chartType = (
    <SplineChart
      data={data.highchartseries}
      title={"Timeseries for " + categoryName}
      categories={allYears}
      yAxisTitle={titleY}
      colors={colors}
    />
  );

  return chartType;
};

export default TimeseriesChart;

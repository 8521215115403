import { useState } from "react";
import { Map } from "leaflet";

import { GeoServerClient } from "utils/ogcClients";
import MapPage from "components/reusables/MapPage";

import { layersConfig } from "./layersConfig";
import { getHarmonizedStatistics } from "utils/apiRequests";
import StandardLeafletMap from "components/reusables/MapComponents/StandardLeafletMap";

import Sidebar from "./Sidebar";
import { SelectedCountryLayerTitle } from "components/reusables/CountryLayerTitle";
import SidebarContent from "./Sidebar/Info";

const INITIAL_YEAR = 2022;
const geometryClient = new GeoServerClient("countries_ghm");

const tabs = [
  { title: <SelectedCountryLayerTitle />, content: <Sidebar /> },
  { title: "Info", content: <SidebarContent /> }
];

const GlobalHarmonizedMapPage = () => {
  const [map, setMap] = useState<Map | null>(null);

  return (
    <MapPage
      map={map}
      initialYear={INITIAL_YEAR}
      layersConfig={layersConfig}
      countryDataGetter={getHarmonizedStatistics}
      geometryClient={geometryClient}
      countriesApiPath="ghm/Countries"
      tabs={tabs}
    >
      <StandardLeafletMap setMap={setMap} showScaleToRestrictLegend={true} />
    </MapPage>
  );
};

export default GlobalHarmonizedMapPage;

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { Accordion, AccordionDetails, AccordionSummary, styled, Typography } from "@mui/material";
import TruncateText from "components/reusables/TruncateText";
import { groupBy } from "lodash";
import { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
import { useQuery } from "react-query";
import { CommoditiesProductI } from "types";
import { getAvailableProducts, getComProducts } from "utils/apiRequests";
import { MapPageContext } from "../../reusables/MapPage";
import { getLayerID } from "./index";

export interface ProductTreeSelectContextI {
  product: CommoditiesProductI | undefined;
  setProduct: Dispatch<SetStateAction<CommoditiesProductI | undefined>>;
  period: string | undefined;
  setPeriod: Dispatch<SetStateAction<string | undefined>>;
  defaultProductKey?: string;
  idToNode: (arg0: string) => CommoditiesProductI;
  nodeToId: (arg0: CommoditiesProductI) => string;
  //  allPeriods: string[];
}

const initialContext: ProductTreeSelectContextI = {
  product: undefined,
  setProduct: () => null,
  period: undefined,
  setPeriod: () => null,

  idToNode: () => {
    return {} as CommoditiesProductI;
  },
  nodeToId: () => ""
  //,
  // allPeriods: []
};

export const ProductTreeSelectContext = createContext(initialContext);

interface RootTreeItemI {
  name: string;
  products: CommoditiesProductAvailableI[];
  hasAvailableChildren: boolean;
}

interface CommoditiesProductAvailableI extends CommoditiesProductI {
  isAvailable: boolean;
}

export const makeTree = (data: CommoditiesProductI[], availableProducts: CommoditiesProductI[]): RootTreeItemI[] => {
  const productsWithAvailableFlag = data.map((i) => ({
    ...i,
    isAvailable: availableProducts.some((p) => p.product_code === i.product_code)
  }));
  const tree = groupBy(productsWithAvailableFlag, "commodity_name");

  return Object.entries(tree).map(([commodity, products]) => {
    const hasAvailableChildren = products.some((i) => i.isAvailable === true);
    return {
      name: commodity,
      products,
      hasAvailableChildren
    };
  });
};

interface CustomTreeItemPropsI {
  hasAvailableChildren?: boolean;
  isAvailable?: boolean;
}

const CustomizedTreeItem = styled(TreeItem)<CustomTreeItemPropsI>(({ theme, hasAvailableChildren, isAvailable }) => ({
  "& .Mui-selected": {
    backgroundColor: `${theme.palette.primary.light} !important`
  },
  "& .MuiTreeItem-label": {
    fontSize: "0.9rem !important",
    color: isAvailable ? "black" : hasAvailableChildren ? "black" : "gray"
  },
  "& .MuiTreeItem-iconContainer": {
    color: "black"
  }
}));

const getIsAvailable = (node: CommoditiesProductI, availableProducts: CommoditiesProductI[]): boolean => {
  if (availableProducts) {
    const found = availableProducts.find((i) => i.product_code === node.product_code);
    if (found) return true;
    else return false;
    // allow selection of products if list of available products was not retrieved for some reason
  } else return true;
};

const CustomizedAccordion = styled(Accordion)(() => ({
  "& .MuiAccordionSummary-root": {
    minHeight: 10
  },
  "& .MuiAccordionSummary-content": {
    margin: 0
  },
  border: "1px solid #ccc",
  borderRadius: "5px 5px 5px 5px !important"
}));

export default function ProductTreeSelect() {
  const { product, setProduct, period, defaultProductKey, idToNode, nodeToId } = useContext(ProductTreeSelectContext);
  const { selectedCountryCode } = useContext(MapPageContext);
  const layer = getLayerID();
  const { data, isLoading } = useQuery(["getCommoditiesTreeProduction", "prd"], getComProducts, {
    staleTime: Infinity
  });

  //Get products for selected period
  const { data: availableProducts, isLoading: loadingAvailableProducts } = useQuery(
    [layer, period, selectedCountryCode],
    getAvailableProducts,
    { staleTime: Infinity }
  );
  const [expanded, setExpanded] = useState<string[]>([]);

  if (loadingAvailableProducts) return <>Loading...</>;
  if (!availableProducts) return null;
  if (!data) return null;

  // only expand if icon was clicked
  const handleToggle = (event: any, nodeIds: string[]) => {
    event.persist();
    event.stopPropagation();
    setExpanded(nodeIds);
  };

  // only select if icon was not clicked
  const handleSelect = (event: any, nodeIds: any) => {
    event.persist();
    event.stopPropagation();
    const iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
    if (!iconClicked) {
      const node = idToNode(nodeIds);

      const isAvailable = getIsAvailable(node, availableProducts);
      if (isAvailable) setProduct(node);
    }
  };

  const tree = makeTree(data, availableProducts);

  const selectedId = product ? nodeToId(product) : undefined;

  return (
    <>
      <CustomizedAccordion sx={{ backgroundColor: "transparent" }} elevation={0} disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ margin: 0, overflow: "hidden" }}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              overflow: "hidden",
              fontWeight: 500
            }}
          >
            {product ? product.product_name : "Select product"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={handleSelect}
            onNodeToggle={handleToggle}
            expanded={expanded}
            selected={selectedId || ""}
            sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
          >
            {tree.map(({ name, hasAvailableChildren, products }) => {
              return (
                <CustomizedTreeItem
                  key={name}
                  nodeId={name}
                  label={<TruncateText text={name} charactersCount={60} />}
                  hasAvailableChildren={hasAvailableChildren}
                >
                  {products.map((i) => {
                    return (
                      <CustomizedTreeItem
                        key={i.product_code}
                        nodeId={nodeToId(i)}
                        label={<TruncateText text={i.product_name} charactersCount={60} />}
                        isAvailable={i.isAvailable}
                      />
                    );
                  })}
                </CustomizedTreeItem>
              );
            })}
          </TreeView>
        </AccordionDetails>
      </CustomizedAccordion>
    </>
  );
}

import { useContext, useState } from "react";
import { Map } from "leaflet";
import { GeoServerClient } from "utils/ogcClients";
import { layersConfig } from "./layersConfig";
import MapPage from "components/reusables/MapPage";
import SidebarContent from "./Sidebar";
import StandardLeafletMap from "components/reusables/MapComponents/StandardLeafletMap";
import { getTMFStatistics } from "utils/apiRequests";
import { SelectedCountryLayerTitle } from "components/reusables/CountryLayerTitle";
import Sidebar from "./Sidebar";
import Info from "./Sidebar/Info";

const INITIAL_YEAR = 2022;
const geometryClient = new GeoServerClient("countries_tmf");

const tabs = [
  { title: <SelectedCountryLayerTitle />, content: <Sidebar /> },
  { title: "Info", content: <Info /> }
];

const TropicalMoistForestPage = () => {
  const [map, setMap] = useState<Map | null>(null);

  return (
    <MapPage
      map={map}
      initialYear={INITIAL_YEAR}
      layersConfig={layersConfig}
      geometryClient={geometryClient}
      sidebarContent={<SidebarContent />}
      countriesApiPath="tmf/Countries"
      canSelectCountry={true}
      countryDataGetter={getTMFStatistics}
      tabs={tabs}
    >
      <StandardLeafletMap setMap={setMap} />
    </MapPage>
  );
};

export default TropicalMoistForestPage;

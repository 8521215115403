import { REPORTERS_COUNTRIES_API_PATH } from "components/pages/CommoditiesTradePage";
import { QueryFunctionContext } from "react-query";
import { CommoditiesProductI, TradesAverageResponseItemI } from "types";
import apiClient from "./apiClient";
import { CountryDataGetterI } from "../components/reusables/MapPage";
import { TmfCategoryType } from "../constants/layersCategories";

export const getForestStatistics = async (info: CountryDataGetterI) => {
  const { countryCode, year } = info;
  const found = countryCode && (await apiClient.get(`/gfa/ForestStatistics/c/${countryCode}/y/${year}`));
  if (!found) return;
  return found.data;
};

export const getForestChange = async (countryId: string) => {
  const found = await apiClient.get(`/gfa/ForestChange/c/${countryId}`);
  if (!found) return;
  return found.data;
};

export interface CountryOptionI {
  country_name: string;
  country_code: string;
  center_lat?: number;
  center_lng?: number;
  is_disabled?: boolean;
}

export interface StatementI {
  source?: string;
  text_before?: string;
  text_after?: string;
  text_between?: string;
  link1_text?: string;
  link1_target?: string;
  link2_text?: string;
  link2_target?: string;
}

export const euCountryOption: CountryOptionI = {
  country_code: "EU27",
  country_name: "European Union",
  center_lat: 50,
  center_lng: 8
};

// TODO rest of world center coords ?
export const rowOption: CountryOptionI = {
  country_code: "EU27",
  country_name: "Rest of the world"
  // center_lat: 50,
  // center_lng: 8
};

export const getCountries = async ({ queryKey }: QueryFunctionContext): Promise<CountryOptionI[]> => {
  const [, countriesApiPath] = queryKey as any;
  const res = await apiClient.get(countriesApiPath);

  if (!res) return [];
  const data = res.data as CountryOptionI[];
  if (countriesApiPath === REPORTERS_COUNTRIES_API_PATH) data.unshift(euCountryOption);

  return data;
};

export const getTrades = async ({ queryKey }: any): Promise<TradesAverageResponseItemI[]> => {
  const [, countryCode, period, product, dataSource] = queryKey;
  if (!product) return Promise.reject("Product missing, trades fetch canceled.");

  const res = await apiClient.get(
    `/com/trades-average/r/${countryCode}/p/${period}/p/${product.product_code}/d/${dataSource}`
  );

  if (!res) return [];
  return res.data;
};

export const getTradesImporters = async ({ queryKey }: any): Promise<TradesAverageResponseItemI[]> => {
  const [, countryCode, period, product, dataSource] = queryKey;
  if (!product) return Promise.reject("Product missing, trades fetch canceled.");

  const res = await apiClient.get(
    `/com/trades-average/p/${countryCode}/p/${period}/p/${product.product_code}/d/${dataSource}`
  );

  if (!res) return [];
  return res.data;
};

export const getProducts = async ({ queryKey }: QueryFunctionContext): Promise<CommoditiesProductI[]> => {
  const [, reporterCode, year] = queryKey as any;
  let res;
  if (reporterCode && year) res = await apiClient.get(`/com/products/r/${reporterCode}/y/${year}`);
  else res = await apiClient.get("/com/products");

  if (!res) return [];
  return res.data;
};

export const getTradedAverageExportsByCountryByPeriod = async ({
  queryKey
}: any): Promise<TradesAverageResponseItemI[]> => {
  const [, countryCode, period, queryParams] = queryKey;

  let path = `/com/trades-average/r/${countryCode}/p/${period}`;
  if (queryParams) {
    const paramsString = new URLSearchParams(queryParams);
    path = path + `?${paramsString}`;
  }

  const res = await apiClient.get(path);

  if (!res) return [];
  return res.data;
};

interface AnnualVariationI {
  reporter_code: string;
  partner_code: string;
  product_code: number;
  period: number;
  value: number;
  unit: string;
  product_name: string;
}

export const getAnnualVariations = async ({ queryKey }: any): Promise<AnnualVariationI[]> => {
  const [, fromCountryCode, toCountryCode, commodityName, dataSource, queryParams] = queryKey;
  let path = `/com/annual-variation/r/${toCountryCode}/p/${fromCountryCode}/c/${commodityName}/d/${dataSource}`;
  if (queryParams) {
    const paramsString = new URLSearchParams(queryParams);
    path = path + `?${paramsString}`;
  }
  const res = await apiClient.get(path);

  if (!res) return [];
  return res.data;
};

export const getPeriods = async ({ queryKey }: QueryFunctionContext): Promise<string[]> => {
  const [path, dataSource] = queryKey as any;
  const res = await apiClient.get(`${path}/d/${dataSource}`);

  if (!res) return [];
  return res.data;
};

export const getUnits = async ({ queryKey }: QueryFunctionContext): Promise<string[]> => {
  const [, dataSource] = queryKey as any;
  const res = await apiClient.get(`/com/units/d/${dataSource}`);

  if (!res) return [];
  return res.data;
};

export const getProductFamilies = async (): Promise<CommoditiesProductI[]> => {
  const res = await apiClient.get("/com/product-families");

  if (!res) return [];
  return res.data;
};

export const getComProducts = async ({ queryKey }: QueryFunctionContext): Promise<CommoditiesProductI[]> => {
  const [, dataSource] = queryKey as any;
  const res = await apiClient.get(`/com/products/d/${dataSource}`);
  if (!res) return Promise.reject("Unable to retrieve prodcuts");
  else return res.data;
};

export const getComProductsImmediate = (dataSource: string): Promise<CommoditiesProductI[]> => {
  const res = apiClient
    .get(`/com/products/d/${dataSource}`)
    .then((res) => res.data)
    .catch((err) => console.error(err));
  return res;
};

export const getPeriodsImmediate = (path: string, dataSource: string): Promise<string[]> => {
  const res = apiClient
    .get(`${path}/d/${dataSource}`)
    .then((res) => res.data)
    .catch((err) => console.error(err));

  return res;
};

export const getTradesAverageAvailableProducts = async ({
  queryKey
}: QueryFunctionContext): Promise<CommoditiesProductI[]> => {
  const [, reporterCode, period, dataSource] = queryKey as any;
  const res = await apiClient.get(
    `/com/trades-average/r/${reporterCode}/p/${period}/d/${dataSource}/available-products`
  );

  if (!res) return [];
  return res.data;
};

export const getAvailableProducts = async ({ queryKey }: QueryFunctionContext): Promise<CommoditiesProductI[]> => {
  const [dataSource, period, selectedCountryCode] = queryKey as any;
  const res = await apiClient.get(
    <string>`/com/available-products/d/${dataSource}` +
      (selectedCountryCode ? `/c/${selectedCountryCode}` : "") +
      (period ? `/p/${period}` : "")
  );

  if (!res) return [];
  return res.data;
};

export const getHarmonizedStatistics = async (info: CountryDataGetterI) => {
  const { countryCode } = info;
  const found = countryCode && (await apiClient.get(`/ghm/statistics/c/${countryCode}`));
  if (!found) return;
  return found.data;
};

export const getTMFStatistics = async (info: CountryDataGetterI) => {
  const { countryCode, selectedLayer } = info;
  if (!selectedLayer || !countryCode) return;
  const selectedCategory = selectedLayer.tmfCategory as TmfCategoryType;
  const apiMethod: string =
    selectedCategory == "deforestation"
      ? "deforestation"
      : selectedCategory == "degradation"
      ? "degradation"
      : selectedCategory == "annual-change"
      ? "acs"
      : "mc";

  const found = await apiClient.get(`/tmf/${apiMethod}/c/${countryCode}`);

  if (!found) return;
  return found.data;
};

interface ACStatistics {
  year: string;

  iso3: string;
  undisturbed_Forest: number;
  total_Forest_Disturbances: number;
  total_Degradation: number;
  degradation_Not_Followed_By_Deforestation: number;
  degradation_Followed_By_Deforestation: number;
  total_Deforestation: number;
  total_Direct_Deforestation: number;
  forest_Conversion_Plantation: number;
  forest_Conversion_Water: number;
  direct_Deforestation_Exc_Plantation_Water: number;
  deforestation_After_Degradation: number;
}

export const getACStatistics = async ({ queryKey }: any): Promise<ACStatistics[]> => {
  const [, countryCode] = queryKey;
  if (!countryCode) return Promise.reject("Country code not valid, search for AC statistics cancelled.");
  const found = await apiClient.get(`/tmf/ac/c/${countryCode}`);

  if (!found) return Promise.reject("Error retrieving ac statistics.");
  return found.data;
};

interface ProductionAnnualVariation {
  period: string;
  country_code: string;
  product_code: string;
  value: number;
  unit: string;
}

export const getProductionAnnualVariationStatistics = async ({
  queryKey
}: any): Promise<ProductionAnnualVariation[]> => {
  const [, dataSource, countryCode, productCode] = queryKey;
  if (!dataSource || !countryCode || !productCode)
    return Promise.reject("Country code and/or product code not valid, search for production statistics cancelled.");
  const found = await apiClient.get(`/com/annual_variation/d/${dataSource}/p/${productCode}/c/${countryCode}`);

  if (!found) return Promise.reject("Error retrieving production annual variation statistics.");
  return found.data;
};

export const getProductionAverageByProduct = async ({ queryKey }: any): Promise<any[]> => {
  const [, dataSource, productCode, period] = queryKey;
  if (!dataSource || !period || !productCode)
    return Promise.reject(" product code and/or period not valid, search for average production cancelled.");
  const found = await apiClient.get(`/com/average/d/${dataSource}/p/${period}/p/${productCode}`);

  if (!found) return Promise.reject("Error retrieving average production.");
  return found.data;
};

export const getProductionAverage = async ({ queryKey }: any): Promise<any[]> => {
  const [, dataSource, countryCode, productCode] = queryKey;
  if (!dataSource || !productCode || !countryCode)
    return Promise.reject(" country code and/or product not valid, search for average production cancelled.");
  const found = await apiClient.get(`/com/average/d/${dataSource}/c/${countryCode}/p/${productCode}`);

  if (!found) return Promise.reject("Error retrieving average production.");
  return found.data;
};

export const getCarbonCountryDescription = async ({ queryKey }: any): Promise<any> => {
  const [, countryCode] = queryKey;
  if (!countryCode) return Promise.reject(" country code search for carbon country description cancelled.");
  const found = await apiClient.get(`/car/getDescription/${countryCode}`);

  if (!found) return Promise.reject("Error retrieving carbon country description.");
  return found.data;
};

export const getCarbonCategories = async (): Promise<any[]> => {
  const found = await apiClient.get(`/car/categories`);
  if (!found) return Promise.reject("Error retrieving carbon fluxes categories.");
  return found.data;
};

export const getStatements = async (): Promise<StatementI[]> => {
  const res = await apiClient.get(`/com/statements/`);
  if (!res) return Promise.reject("Unable to retrieve statements");
  else return res.data;
};

export const getTrendsProductionsCharts = async ({ queryKey }: any): Promise<any> => {
  const [categoryLayer, selectedCountryCode, productCode] = queryKey;
  if (!categoryLayer || !productCode)
    return Promise.reject(
      " Category  and/or Product code and/or Data of Year not valid, search for trends production cancelled."
    );
  const countryCode = selectedCountryCode === "" ? "" : `c/${selectedCountryCode}`;
  const found = await apiClient.get(`/com/trends/d/${categoryLayer}/p/${productCode}/${countryCode}`);

  if (!found) return Promise.reject("Error retrieving average production.");
  return found.data;
};

export const getCarbonDataCharts = async ({ queryKey }: any): Promise<any> => {
  const [, categoryLayer, selectedCountryCode] = queryKey;
  if (!categoryLayer || !selectedCountryCode)
    return Promise.reject(" Category  and/or country code  not valid, search for carbon data cancelled.");
  const found = await apiClient.get(`/car/getData/c/${selectedCountryCode}/c/${categoryLayer}`);

  if (!found) return Promise.reject("Error retrieving carbon data.");
  return found.data;
};

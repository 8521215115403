import Control, { ControlPositionType } from "./LeafletCustomControl";
import MapButton from "./MapButton";
import { Add, Remove } from "@mui/icons-material";
import { useMap, useMapEvents } from "react-leaflet";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";

interface PropsI {
  position: ControlPositionType;
}

const mapIconStyle = { padding: 1, height: 30, width: 30 };

const ZoomInOutButtons = ({ position }: PropsI) => {
  const map = useMap();
  const maxZoom = map.getMaxZoom();
  const minZoom = map.getMinZoom();
  const zoom = map.getZoom();
  const [currentZoom, setCurrentZoom] = useState(zoom);

  const zoomInDisabled = currentZoom >= maxZoom;
  const zoomOutDisabled = currentZoom <= minZoom;

  const mapEvents = useMapEvents({
    zoomend: () => setCurrentZoom(mapEvents.getZoom())
  });

  const handleZoomIn = () => {
    map.zoomIn();
  };

  const handleZoomOut = () => {
    map.zoomOut();
  };

  useEffect(() => {
    // Custom event fired in SelectedCountryPolygon when a country is selected
    // To prevent the zoom from remaining disabled
    map.on("fit-bounds", () => setCurrentZoom(mapEvents.getZoom()));
  }, []);

  return (
    <Control position={position}>
      <Box className="flex flex-column gap-10">
        <MapButton
          style={mapIconStyle}
          onClick={handleZoomIn}
          tooltip="Zoom In"
          disabled={zoomInDisabled}
          innerPadding={2}
        >
          <Add />
        </MapButton>
        <MapButton
          style={mapIconStyle}
          onClick={handleZoomOut}
          tooltip="Zoom Out"
          disabled={zoomOutDisabled}
          innerPadding={2}
        >
          <Remove />
        </MapButton>
      </Box>
    </Control>
  );
};

export default ZoomInOutButtons;

import { Box, Typography } from "@mui/material";
import SummaryPage, { SummaryItem } from "components/reusables/SummaryPage";

import colorByPageName from "constants/colorByPageName";
import StyledLink from "components/reusables/StyledLink";

import { removeOpacity } from "components/reusables/removeColorOpacity";

const title = "Global Forest Monitoring";
// const color = removeOpacity.color(colorByPageName[title]);
const color = colorByPageName[title];
const minWidth = 450;

const GfaDescription = () => {
  return (
    <Typography>
      This section presents the global forest attribute layers developed by JRC. The analysis scheme and data products
      were designed specifically for the Web platform of the EU observatory on deforestation and forest degradation.The
      spatial forest coverage is derived from the Copernicus Global Land Cover dataset for the assessment year 2019
      (accessed in February 2022). The original Copernicus data segments were processed into a continuous global forest
      mask which is compliant with the FAO forest definition. This forest mask has then be used to produce various
      forest attribute layers summarising the spatial status of forest cover and its degree of forest degradation. These
      layers are visualized using the original WGS84 map projection for explanatory and user-friendly visualisation.
      Statistical estimates of the forest attribute layers are derived using an equal area projection for a total of 278
      reporting units, comprised of 255 GISCO countries, 21 global ecological zones, and the full global coverage
    </Typography>
  );
};

const GhmDescription = () => {
  return (
    <Typography>
      The map of forest cover changes shows the following drivers of forest cover changes at the global scale and on an
      annual basis between 2016 to 2022: (i) deforestation for commodity production, (ii) forest harvest by intensity
      level in areas dedicated to large-scale forestry operations (also referred to as harvested areas), (iii)
      disturbances which include (a) forest cover loss due to fires inside harvested areas, (b) forest cover loss due to
      fires outside harvested areas, (c) forest cover loss due to extreme events, e.g., wind throw, insect outbreaks,
      inside harvested areas and (d) small-scale disturbances in the tropical moist forests due to selective logging,
      understory fire or natural disturbances such as wind breaks or drought.
    </Typography>
  );
};

const TmfDescription = () => {
  return (
    <Typography>
      The Tropical Moist Forest dataset from the Joint Research Centre provides information on changes in forest cover
      from 1990 up to the year 2021 in the humid tropics. The wall-to-wall maps at 0.09 ha resolution (30m) depict the
      tropical moist forest extent and the related disturbances (deforestation and degradation), and post-deforestation
      recovery (or forest regrowth). Deforestation refers to a change in land cover (from forest to non-forested land)
      when degradation refers to a short-duration disturbance in a forest remaining forested such as selective logging,
      fires and unusual weather events (hurricanes, droughts, blowdown).
    </Typography>
  );
};

//const EufcDescription = () => {
//  return (
//    <Typography>
//      This section presents the european forest attribute layers developed by JRC. (to be completed)
//    </Typography>
//  );
//};

const Gfc2020Description = () => {
  return (
    <Typography>
      The global map of forest cover is produced from a combination of existing global spatial layers on land cover,
      land use and tree height. It aims at representing the state of standing forest cover by 31 December 2020. The
      Global Land cover from the{" "}
      <StyledLink
        href="https://esa-worldcover.org/en"
        target="_blank"
        color={color}
        aria-label="Link to ESA Worldwide land cover mapping (external link will be opened in a new window)"
      >
        ESA World Cover
      </StyledLink>{" "}
      project serves as baseline layer to define the extent of tree cover for year 2020 at 10 m spatial resolution. The
      approach used to produce this combined map and its validation will be described in a technical report expected to
      be released by March 2024. The map may be revised if new information, additional large-scale data layers or
      revised global spatial data layers would be made available for year 2020.
      <br />
      <br />
      The global map of forest cover for year 2020 has no legal value per se but may serve as a tool to comply with the
      Regulation on deforestation-free supply chains. For example, it may help assess the deforestation risk, without
      dispensing operators and Member States from the duty to carry out due diligence.
    </Typography>
  );
};

const MainContent = () => {
  return (
    <Box>
      <SummaryItem
        title="Global map of Forest Cover for year 2020"
        content={<Gfc2020Description />}
        color={color}
        mainColor={color}
        minWidth={minWidth}
        linkTo="gfc2020"
        ariaLabel="Global map of Forest Cover for year 2020"
      />
      <SummaryItem
        title="Global Forest Attributes"
        content={<GfaDescription />}
        color={color}
        mainColor={color}
        minWidth={minWidth}
        linkTo="gfa"
        ariaLabel="Global forest attributes"
      />
      <SummaryItem
        title="Global Map of Forest Cover Changes and their Drivers"
        content={<GhmDescription />}
        color={color}
        mainColor={color}
        minWidth={minWidth}
        linkTo="ghm"
        ariaLabel="Global Map of Forest Cover Changes"
      />
      <SummaryItem
        title="Tropical Moist Forest"
        content={<TmfDescription />}
        color={color}
        mainColor={color}
        minWidth={minWidth}
        linkTo="tmf"
        ariaLabel="Tropical Moist Forest"
      />
    </Box>
  );
};

/*

      <SummaryItem
        title="European Forest Attributes"
        content={<EufcDescription />}
        color={color}
        mainColor={color}
        linkTo="eufc"
      />

 */
const GlobalForestMonitoringSummaryPage = () => {
  return <SummaryPage backgroundImgPath="background_gfm" title={title} color={color} mainContent={<MainContent />} />;
};

export default GlobalForestMonitoringSummaryPage;

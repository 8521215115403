import { Box } from "@mui/material";
import { useContext } from "react";

import { MapPageContext } from "components/reusables/MapPage";
import {
  apiFieldsMappingACS_MCStatistics,
  apiFieldsMappingDEFStatistics,
  apiFieldsMappingDEGStatistics
} from "./utils/apiFieldsMapping";
import { ACSDataKeys, DEFDataKeys, DEGDataKeys, MCDataKeys } from "./utils/keysGroups";
import { Chart, makeData, makeTitle } from "utils/chartsUtils";

import { TmfCategoryType } from "constants/layersCategories";
import WithInfoTooltipIcon from "components/reusables/WithInfoTooltipIcon";
import { greyTextColor } from "constants/colors";

import layersDescriptions, { tmfCategoryToTitle } from "../layersDescriptions";
import ColumnChart from "../../../charts/ColumnChart";

const dataKeysByCategory = {
  degradation: DEGDataKeys,
  deforestation: DEFDataKeys,
  "annual-change": ACSDataKeys,
  "main-classes": MCDataKeys
};

const NoLayerSelectedMessage = () => {
  return <Box sx={{ padding: 1 }}>SELECT A LAYER TO SEE THE DATA</Box>;
};

const Involve = () => {
  const { selectedLayers, setActiveTabIndex } = useContext(MapPageContext);

  const selectedCategory = selectedLayers[0].tmfCategory as TmfCategoryType;
  if (selectedLayers?.length === 0 || !selectedCategory) return <NoLayerSelectedMessage />;

  return (
    <>
      <WithInfoTooltipIcon
        color={greyTextColor}
        tooltipProps={{ title: "More info" }}
        onIconClick={() => setActiveTabIndex(1)}
      >
        {makeTitle(tmfCategoryToTitle[selectedCategory])}
      </WithInfoTooltipIcon>
      <Box color={greyTextColor}>{layersDescriptions[selectedCategory]}</Box>
      <br />
    </>
  );
};

const LayerCharts = () => {
  const { selectedLayers, selectedCountryData, selectedYear } = useContext(MapPageContext);
  if (selectedLayers?.length === 0) return <NoLayerSelectedMessage />;

  const selectedCategory = selectedLayers[0].tmfCategory as TmfCategoryType;

  if (!selectedCountryData) return null;

  switch (selectedCategory) {
    case "deforestation":
    case "degradation":
      return (
        <>
          <Involve />
          <Chart
            categories={dataKeysByCategory[selectedCategory]}
            title={"&nbsp;"}
            apiMapping={
              selectedCategory == "deforestation" ? apiFieldsMappingDEFStatistics : apiFieldsMappingDEGStatistics
            }
            yAxisTitle={"Mha"}
            colors={yearColors}
            hideLegend={true}
          />
        </>
      );
      break;
  }

  const dataNew =
    selectedCountryData instanceof Array
      ? selectedCountryData.filter((item: any) => item.year === selectedYear)
      : [selectedCountryData];

  if (!dataNew || dataNew.length !== 1) return null;
  const data = makeData(dataNew[0], dataKeysByCategory[selectedCategory], apiFieldsMappingACS_MCStatistics, "Mha");
  const categories = data.map((item) => item.name);
  const title = selectedCategory === "annual-change" ? "" + selectedYear : "&nbsp; ";
  return (
    <>
      <Involve />
      <ColumnChart
        data={data}
        categories={categories}
        yAxisTitle={"Mha"}
        title={title}
        hideLegend={true} //selectedCategory === "annual-change" ? true : false}
      />
    </>
  );
};

export default LayerCharts;

const yearColors = [
  /*
    "#2892c7",
  "#2d94c4",
  "#3a97c2",
  "#4b9bbd",
  "#579fba",
  "#5ca1b8",
  "#69a8b3",
  "#78adad",
  "#82b3a6",
  "#8db8a2",
  "#99bf9d",
  "#a4c497",
  "#b1cc93",
  "#bbd18a",
  "#c7d986",
  "#d2e080",
  "#dbe67a",
    */
  "#e3eb73",
  "#eef26b",
  "#fafa64",
  "#faed5f",
  "#fce158",
  "#fcd553",
  "#fcc84e",
  "#fcbd47",
  "#fcaf42",
  "#fca03d",
  "#fa9537",
  "#fa8932",
  "#f77c2f",
  "#f7722a",
  "#f56325",
  "#f25622",
  "#f0471d",
  "#ed3a1a",
  "#eb2917",
  "#e81014",
  "#d00909",
  "#b30800",
  "#9c0700"
];

import { GhmCategoryType } from "../../../constants/layersCategories";
import { MapServerClient } from "utils/ogcClients";
import { GetMapParametersI, LayerConfigI, LayerDefinitionI } from "../../../types";
import {
  getLayersConfig,
  handleLayersName,
  makeCommonConfig,
  MakeConfigParamsI
} from "../../../utils/layerConfigUtils";

const mapFileName = "GHM";
const mapServerClient = new MapServerClient(mapFileName);

const layersDefinitions: LayerDefinitionI[] = [
  {
    type: "years-group",
    name: "GHM_mix",
    layerNamePrefix: "GHM_mix_",
    title: "Global Map of Forest Cover Changes and their Drivers",
    years: [2016, 2017, 2018, 2019, 2020, 2021, 2022],
    yearSelectionType: "slider",
    defaultSelected: true,
    defaultSelectedYear: 2022
  }
];

const makeConfig = (props: MakeConfigParamsI): LayerConfigI => {
  const { definition, year } = props;
  const { name } = definition;

  const params: GetMapParametersI = {
    layers: handleLayersName(definition, year),
    ...mapServerClient.WMSParams
  };

  return {
    ...makeCommonConfig(props, mapServerClient),
    params,
    ghmCategory: "harmonized" as GhmCategoryType
  };
};

export const layersConfig = getLayersConfig(layersDefinitions, makeConfig);

import { NavLink } from "react-router-dom";
import { Box, IconButton, Popover } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { MoreVert, NavigateNext } from "@mui/icons-material";
import { BREADCRUMB_HEIGHT } from "layout/Header";
import { MatchWithHandle } from "router";
import colorByPageName, { PageTitleType } from "constants/colorByPageName";
import { Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { GlobalContext } from "App";
import { useLocation } from "react-router";
import { MapPageContextI } from "components/reusables/MapPage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { get } from "lodash";
import { ProductTreeSelectContextI } from "../components/pages/CommoditiesProductionPage/ProductTreeSelect";
import { partialTitle } from "../components/pages/CommoditiesProductionPage";

const Separator = <NavigateNext fontSize="small" sx={{ color: "white" }} />;

const getFirstMapLayerTitle = (context?: MapPageContextI, removeTitle = false) => {
  const title = removeTitle ? "" : get(context, "selectedLayers[0].title", "");
  const year = get(context, "selectedLayers[0].year", "");
  return title + (year !== "" ? " " + year : "");
};

const AdditionalBreadcrumbInfo = () => {
  const { contexts } = useContext(GlobalContext);
  const { mapPage, trade } = contexts;
  const { pathname } = useLocation();

  let content: ReactNode = "";

  // Using path-based logic. Set specific prop in context for more granular use-cases
  if (pathname === "/forest/gfa") content = getFirstMapLayerTitle(mapPage);
  if (pathname === "/forest/ghm") content = getFirstMapLayerTitle(mapPage, true);
  if (pathname === "/forest/gfm") content = getFirstMapLayerTitle(mapPage);
  if (pathname === "/forest/tmf") content = getFirstMapLayerTitle(mapPage);
  if (pathname === "/commodities/trade") content = trade?.product?.product_name;
  if (pathname === "/commodities/production") {
    content = getFirstMapLayerTitle(mapPage);
    const { production } = contexts;
    if (production && production != undefined) content = content + partialTitle(production);
  }
  return (
    <Box sx={{ pl: 1, display: "flex", alignItems: "center", gap: 1 }} className="crumb-link-active">
      {content && <span>-</span>}
      {content}
    </Box>
  );
};

interface MainBreadcrumbsPropsI {
  showMainHeader: boolean;
  setShowMainHeader: Dispatch<SetStateAction<boolean>>;
  setIsToggledByUser: Dispatch<SetStateAction<boolean>>;
  matches: MatchWithHandle[];
  isSmallScreen: boolean;
}

interface BreadcrumbsPropsI {
  matches: MatchWithHandle[];
}

const BreadcrumbsMobile = ({ matches }: BreadcrumbsPropsI) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const newMatches = [...matches].filter((match) => Boolean(match.handle?.crumb));
  const lastMatch = newMatches.pop();

  return (
    <Box className="flex items-center">
      {newMatches.length > 0 && (
        <>
          <IconButton onClick={handleClick} size="small" sx={{ margin: 0, color: "white" }}>
            <MoreVert />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            className="white-background"
          >
            {newMatches.map((match, index, array) => {
              return (
                <>
                  <Box className="flex items-center" sx={{ p: 1 }}>
                    <NavLink key={index} to={match.pathname} className="crumb-link" onClick={handleClose}>
                      {match.handle.crumb}
                    </NavLink>
                  </Box>
                  {index !== array.length - 1 && <hr color="#005c00" style={{ margin: 0 }} />}
                </>
              );
            })}
          </Popover>
        </>
      )}
      {lastMatch?.handle?.crumb}
    </Box>
  );
};

export default function MainBreadcrumbs(props: MainBreadcrumbsPropsI) {
  let color = "#0b5830"; // only home page
  const { pathname } = useLocation();
  if (pathname === "/")
    return (
      <Box
        sx={{
          height: BREADCRUMB_HEIGHT,
          color: "white",
          backgroundColor: color,
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap"
        }}
        pl={{ xs: 1, sm: 3 }}
        pr={{ xs: 1, sm: 3 }}
      ></Box>
    );

  const { showMainHeader, setShowMainHeader, setIsToggledByUser } = props;

  // const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isSmallScreen = false;

  const crumbs = props.matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match, index, array) => {
      const isActive = array.length - 1 === index;

      return (
        <NavLink key={index} to={match.pathname} className={isActive ? "crumb-link-active" : "crumb-link"}>
          {match.handle.crumb}
        </NavLink>
      );
    });

  const nodesWithColors = props.matches.filter((i) => {
    const title: PageTitleType = i.handle?.crumb;
    const hasColor = colorByPageName[title];
    if (hasColor) return true;
  });

  if (nodesWithColors.length > 0) {
    // The last one is the most specific match by react-router
    const nodeTitle: PageTitleType = nodesWithColors[nodesWithColors.length - 1].handle.crumb;
    const nodeColor = colorByPageName[nodeTitle];
    if (nodeColor) color = nodeColor;
  }

  return (
    <Box
      sx={{
        height: BREADCRUMB_HEIGHT,
        color: "white",
        backgroundColor: color.replace(/[\d.]+\)$/g, "1)"),
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap"
      }}
      pl={{ xs: 1, sm: 3 }}
      pr={{ xs: 1, sm: 3 }}
    >
      <Box className="flex" sx={{ maxWidth: "100%", overflow: "auto" }}>
        {!isSmallScreen && (
          <Breadcrumbs
            sx={{
              overflowX: "auto",
              "& .MuiBreadcrumbs-ol ": {
                flexWrap: "nowrap"
              },
              "& .MuiBreadcrumbs-li ": {
                whiteSpace: "nowrap"
              },
              "& .MuiButtonBase-root  ": {
                bgcolor: "transparent",
                border: "1px solid #eee",
                color: "#eee"
              }
            }}
            separator={Separator}
          >
            {crumbs}
          </Breadcrumbs>
        )}
        {isSmallScreen && <BreadcrumbsMobile matches={props.matches} />}
        <AdditionalBreadcrumbInfo />
      </Box>
      <Box sx={{ ml: "auto" }}>
        <IconButton
          onClick={() => {
            setShowMainHeader(!showMainHeader);
            setIsToggledByUser(true);
          }}
          size="small"
          sx={{ borderRadius: 0, backgroundColor: "#ffffff2f", color: "white" }}
          aria-label={!showMainHeader ? "Expand to see the header" : "Hide the header"}
        >
          {!showMainHeader && <ExpandMoreIcon />}
          {showMainHeader && <ExpandLessIcon />}
        </IconButton>
      </Box>
    </Box>
  );
}

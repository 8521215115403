import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { makeTitle } from "utils/chartsUtils";
import { darkGreen, greyTextColor } from "constants/colors";
import StyledLink from "../../../reusables/StyledLink";
import colorByPageName from "../../../../constants/colorByPageName";

const PADDING = 3;
const color = colorByPageName["Tropical Moist Forest"];

const SidebarContent = () => {
  return (
    <>
      <Box padding={PADDING} color={color} sx={{ textAlign: "justify" }}>
        The maps show land use CO&#8322; fluxes sourced from National Greenhouse gas Inventories (NGHGIs) communicated
        via a range of country reports to the UNFCCC. For each country, we attempted to select the most recent and
        complete source of data, with expert judgement in a few cases. CO&#8322; fluxes are allocated to the classes
        Forest (excl. organic soils), Deforestation, Other non-forest land uses, Organic soils, and Harvested wood
        products, with data gaps filled without altering the levels and trends of the reported data{" "}
        <StyledLink
          href="https://essd.copernicus.org/articles/14/4643/2022/essd-14-4643-2022.html"
          target="_blank"
          color={color}
          aria-label="Link to article (external link will be opened in a new window)"
        >
          Grassi et al. 2022
        </StyledLink>
        .<br />
        <br />
        In the graphs, we further compare NGHGI estimates for 2000-2022 with independent global land use emission
        datasets: <br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;(i){" "}
        <StyledLink
          href="https://globalcarbonbudget.org/"
          target="_blank"
          color={color}
          aria-label="Link to Global Carbon Budget (external link will be opened in a new window)"
        >
          Global Carbon Budget (GCB)
        </StyledLink>{" "}
        data from{" "}
        <StyledLink
          href="https://essd.copernicus.org/articles/15/5301/2023/"
          target="_blank"
          color={color}
          aria-label="Link to article (external link will be opened in a new window)"
        >
          Friedlingstein et al. (2023)
        </StyledLink>{" "}
        , using three bookkeeping models to estimate CO&#8322; fluxes from Forest, Deforestation and Other transitions,
        and external datasets to estimate CO&#8322; emissions from Organic soils. Forest fluxes from the GCB are
        adjusted to the NGHGI definition of human-induced CO&#8322; sink using the methodology from{" "}
        <StyledLink
          href="https://essd.copernicus.org/articles/15/1093/2023/"
          target="_blank"
          color={color}
          aria-label="Link to article (external link will be opened in a new window)"
        >
          Grassi et al. (2023)
        </StyledLink>
        . <br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;(ii){" "}
        <StyledLink
          href="https://www.globalforestwatch.org/"
          target="_blank"
          color={color}
          aria-label="Link to Global Forest Watch (external link will be opened in a new window)"
        >
          Global Forest Watch (GFW)
        </StyledLink>{" "}
        data from Gibbs et al. (in preparation, update of{" "}
        <StyledLink
          href="https://www.nature.com/articles/s41558-020-00976-6"
          target="_blank"
          color={color}
          aria-label="Link to article (external link will be opened in a new window)"
        >
          Harris et al. 2021
        </StyledLink>
        ) includes provisional CO&#8322; fluxes from forests and deforestation from 2001 onwards, estimated by
        integrating ground data into a geospatial GHG monitoring framework. Here, CO&#8322; fluxes linked to shifting
        agriculture are allocated either to the Forest or Deforestation classes. <br />
        <br />
        For more information, please see the{" "}
        <StyledLink
          href="/Frequently_Asked_Questions_EUFO.docx"
          target="_blank"
          color={color}
          aria-label="Link to Frequently asked questions(link to word file to be downloaded)"
        >
          Frequently asked questions
        </StyledLink>{" "}
        and the{" "}
        <StyledLink
          href="/carbon/methodological documentation.docx"
          target="_blank"
          color={color}
          aria-label="Link to carbon methological documentation(link to word file to be downloaded)"
        >
          Methodological documentation
        </StyledLink>
        .
        <br />
        <br />
      </Box>
    </>
  );
};

export default SidebarContent;

import { TmfCategoryType } from "../../../constants/layersCategories";
import { MapServerClient } from "utils/ogcClients";
import { GetMapParametersI, LayerConfigI, LayerDefinitionI } from "../../../types";
import { LatLngBounds } from "leaflet";
import {
  getLayersConfig,
  handleLayersName,
  makeCommonConfig,
  MakeConfigParamsI
} from "../../../utils/layerConfigUtils";

const mapFileName = "TMF";
const mapServerClient = new MapServerClient(mapFileName);
const TMFBounds = new LatLngBounds(
  [-40.1078011423115868, -110.0001438448900046],
  [79.99999999999973, 180.0000148869775387]
);

export const layersDefinitions: LayerDefinitionI[] = [
  {
    type: "years-group",
    name: "annual-change",
    layerNamePrefix: "AC",
    title: "Annual Change",
    years: [
      2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
      2019, 2020, 2021, 2022
    ],
    yearSelectionType: "slider",
    defaultSelected: true,
    defaultSelectedYear: 2022
  },
  {
    type: "layer",
    name: "deforestation",
    layerName: "DEF",
    title: "Deforestation year"
  },
  {
    type: "layer",
    name: "degradation",
    layerName: "DEG",
    title: "Degradation year"
  },
  {
    type: "layer",
    name: "main-classes",
    layerName: "TM_MC",
    title: "Transition Map - Main Classes"
  }
];

const makeConfig = (props: MakeConfigParamsI): LayerConfigI => {
  const { definition, year } = props;
  const { name } = definition;

  const params: GetMapParametersI = {
    layers: handleLayersName(definition, year),
    ...mapServerClient.WMSParams
  };

  return {
    ...makeCommonConfig(props, mapServerClient),
    params,
    bounds: TMFBounds,
    tmfCategory: name as TmfCategoryType
  };
};

export const layersConfig = getLayersConfig(layersDefinitions, makeConfig);

import CountryDescription from "./CountryDescription";
import TimeseriesChart from "./TimeseriesChart";

import { useContext } from "react";
import { MapPageContext } from "../../../reusables/MapPage";
import ExtraChart from "./ExtraChart";
import { getCountryName } from "../../../reusables/CountryLayerTitle";
import { Box } from "@mui/material";

const CarbonCharts = () => {
  const { selectedCountryCode } = useContext(MapPageContext);
  const countryName = getCountryName(useContext(MapPageContext));

  return (
    <>
      <CountryDescription />
      <Box style={{ marginLeft: "5px", marginRight: "5px" }}>
        <b>Annual CO2 fluxes</b>
        <br />
        <TimeseriesChart countryCode={selectedCountryCode} countryName={countryName} />
      </Box>
      <ExtraChart />
    </>
  );
};
export default CarbonCharts;

import { createBrowserRouter, useMatches, RouteObject } from "react-router-dom";

import Layout from "layout/Layout";
import NotFoundPage from "components/NotFoundPage";
import HomePage from "components/pages/HomePage/HomePage";
import BackgroundInformation from "components/pages/HomePage/BackgroundInformation";

import GlobalForestMonitoringSummaryPage from "components/pages/GlobalForestMonitoringSummaryPage";
import GlobalForestAttributesPage from "components/pages/GlobalForestAttributesPage";
import TropicalMoistForestPage from "components/pages/TropicalMoistForestPage";
import GlobalHarmonizedMapPage from "components/pages/GlobalHarmonizedMapPage";
import EuropeanForestAttributesPage from "components/pages/EuropeanForestAttributesPage";
import ReferenceMapPage from "components/pages/ReferenceMapPage";

import CommoditiesSummaryPage from "./components/pages/CommoditiesSummaryPage";
import CommoditiesTradePage from "components/pages/CommoditiesTradePage";
import CommoditiesProductionPage from "components/pages/CommoditiesProductionPage";
import { PageTitleType } from "constants/colorByPageName";
import NationalReportingPage from "components/pages/NationalReportingPage";
import ToolsForEUForestMonitoringPage from "components/pages/ToolsForEUForestMonitoringPage";
import AccessibilityPage from "components/pages/AccessibilityPage";
import CarbonSummaryPage from "./components/pages/CarbonSummaryPage";
import GlobalCarbonPage from "./components/pages/GlobalCarbonPage";
/// Global Variable
let isFirstLoading = true;

// service
const trackPage = () => {
  if (window.$wt.analytics.isTrackable()) {
    window.$wt.trackPageView();
  }
};

interface HandleI {
  crumb: PageTitleType;
  isMapPage?: boolean; // this is for hiding the main header
}

type DefaultMatch = ReturnType<typeof useMatches>[number];

export interface MatchWithHandle extends DefaultMatch {
  handle: HandleI;
}

type CustomRouteObject = Omit<RouteObject, "handle"> & {
  handle?: HandleI;
  children?: CustomRouteObject[];
};

export const routeObjects: CustomRouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFoundPage />,
    handle: { crumb: "Home" as any },
    children: [
      { element: <HomePage />, index: true } as any,
      {
        element: <BackgroundInformation />,
        path: "/info",
        index: true,
        handle: { crumb: "Background information" }
      } as any,
      {
        path: "/forest",
        handle: { crumb: "Global Forest Monitoring" },
        children: [
          { element: <GlobalForestMonitoringSummaryPage />, index: true },
          {
            path: "tmf",
            element: <TropicalMoistForestPage />,
            handle: { crumb: "Tropical Moist Forest" as PageTitleType, isMapPage: true }
          },
          {
            path: "gfa",
            element: <GlobalForestAttributesPage />,
            handle: { crumb: "Global Forest Attributes" as PageTitleType, isMapPage: true }
          },
          {
            path: "ghm",
            element: <GlobalHarmonizedMapPage />,
            handle: { crumb: "Global Map of Forest Cover Changes and their Drivers" as PageTitleType, isMapPage: true }
          },
          {
            path: "eufc",
            element: <EuropeanForestAttributesPage />,
            handle: { crumb: "European Forest Attributes" as PageTitleType, isMapPage: true }
          },
          {
            path: "gfc2020",
            element: <ReferenceMapPage />,
            handle: { crumb: "Global map of Forest Cover for year 2020" as PageTitleType, isMapPage: true }
          }
        ]
      },
      {
        path: "/commodities",
        handle: { crumb: "Production and trade of commodities" },
        children: [
          { element: <CommoditiesSummaryPage />, index: true },
          {
            path: "production",
            element: <CommoditiesProductionPage />,
            handle: { crumb: "Production" as PageTitleType, isMapPage: true }
          },
          {
            path: "trade",
            element: <CommoditiesTradePage />,
            handle: { crumb: "Trade" as PageTitleType, isMapPage: true }
          }
        ]
      },
      {
        path: "/carbon",
        handle: { crumb: "Global land use carbon fluxes" },
        children: [
          { element: <CarbonSummaryPage />, index: true },
          {
            path: "fluxes",
            element: <GlobalCarbonPage />,
            handle: { crumb: "Carbon fluxes from land 2000-2022" as PageTitleType, isMapPage: true }
          }
        ]
      },
      {
        path: "/reporting",
        handle: { crumb: "Reporting at country level" },
        children: [{ element: <NationalReportingPage />, index: true }]
      },
      {
        path: "/tools",
        handle: { crumb: "EU tools for forest monitoring" },
        children: [{ element: <ToolsForEUForestMonitoringPage />, index: true }]
      },
      {
        path: "/accessibility",
        handle: { crumb: "EU Accessibility statement" },
        children: [{ element: <AccessibilityPage />, index: true }]
      }
    ]
  }
];

// can't find a way to make it digest the overridden RouteObject type
const router = createBrowserRouter(routeObjects as any);

router.subscribe(() => {
  if (isFirstLoading) {
    const observer = new MutationObserver(() => {
      if (document.contains(document.querySelector("div.analytics.wt.wt-analytics"))) {
        observer.disconnect();
        isFirstLoading = false;
        setTimeout(() => {
          // call analytics service
          trackPage();
        }, 100);
      }
    });
    observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
  } else {
    setTimeout(() => {
      // call analytics service
      trackPage();
    }, 100);
  }
});

export default router;

import { Box, styled, Theme, Typography, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import useHeaderHeight from "hooks/useHeaderHeight";
import GFM from "icons/GFM";
import PTC from "icons/PTC";
import TEUFM from "icons/TEUFM";
import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import BarWhoWeAre from "layout/WhoWeAre";
import CFDH from "icons/CFDH";

interface MenuItemI {
  path: string;
  icon: ReactNode;
  title: string;
}

const homeItems: MenuItemI[] = [
  {
    path: "/forest",
    icon: <GFM fill="white" />,
    title: "GLOBAL FOREST MONITORING"
  },
  {
    path: "/commodities",
    icon: <PTC fill="white" />,
    title: "PRODUCTION AND TRADE OF COMMODITIES"
  },
  {
    path: "/carbon",
    icon: <CFDH fill="white" />,
    title: "GLOBAL LAND USE CARBON FLUXES"
  },
  /* {
    path: "/reporting",
    icon: <NR fill="white" />,
    title: "REPORTING AT COUNTRY LEVEL"
  },*/
  {
    path: "/tools",
    icon: <TEUFM fill="white" />,
    title: "EU TOOLS FOR FOREST MONITORING"
  }
];

const IconWrapper = styled("div")`
  border-radius: 50%;
  background-color: #a3dac3;
  display: flex;
  padding: 5%;
  width: 6rem;
  height: 6rem;

  svg {
    height: 100%;
  }
`;

const STypography = styled(Typography)`
  font-weight: 500 !important;
  text-align: center !important;
  font-size: 1.375rem !important;
  color: rgb(255, 255, 255, 1);
`;

const HomeCard = styled(Link)`
  max-width: 150px;
  padding: 5px;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  &:hover .MuiTypography-root {
    background-color: rgb(255, 255, 255, 1);
  }
  &:hover[data-color="0"] {
    .MuiTypography-root {
      color: #006032;
    }
    .icon {
      background-color: #006032;
    }
  }
  &:hover[data-color="1"] {
    .MuiTypography-root {
      color: #008142;
    }

    .icon {
      background-color: #008142;
    }
  }
  &:hover[data-color="2"] {
    .MuiTypography-root {
      color: #5e8463;
    }

    .icon {
      background-color: #5e8463;
    }
  }

  &:hover[data-color="3"] {
    .MuiTypography-root {
      color: #45ad45;
    }
    .icon {
      background-color: #45ad45;
    }
  }
`;

const HomePage = () => {
  const headerHeight = useHeaderHeight();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [showWhoWeAre, setShowHideWhoWeAre] = useState(false);

  return (
    <>
      <BarWhoWeAre showWhoWeAre={showWhoWeAre} setShowHideWhoWeAre={setShowHideWhoWeAre} />
      <Box className="flex flex-wrap" sx={{ minHeight: `calc(100vh - ${headerHeight}px)` }}>
        <div className="home-overlay"></div>
        <div
          className={clsx(
            "w-full items-top flex m-auto flex-wrap justify-center gap-30 cursor-pointer",
            isSmallScreen && " py-20"
          )}
          style={{ maxWidth: 800 }}
        >
          {homeItems.map((item, index) => {
            const { icon, title, path } = item;
            return (
              <HomeCard
                key={index}
                data-color={index}
                className="flex flex-column  items-center gap-10 "
                to={path}
                aria-label={`Link to ${title} section`}
              >
                <IconWrapper className={"icon"}>{icon}</IconWrapper>
                <STypography variant="h2">{title}</STypography>
              </HomeCard>
            );
          })}
        </div>
      </Box>
    </>
  );
};

export default HomePage;

import { useContext, useState } from "react";
import { Map } from "leaflet";
import { defaultLayer, layersConfig, layersDefinitions } from "./layersConfig";
import MapPage, { MapPageContext } from "components/reusables/MapPage";
import StandardLeafletMap from "components/reusables/MapComponents/StandardLeafletMap";
import { GeoServerClient } from "utils/ogcClients";
import ControlsPanel from "components/pages/CommoditiesProductionPage/ControlsPanel";

import {
  ProductTreeSelectContext,
  ProductTreeSelectContextI
} from "components/pages/CommoditiesProductionPage/ProductTreeSelect";
import { SelectedCountryLayerTitle } from "components/reusables/CountryLayerTitle";
import { getComProducts, getComProductsImmediate, getPeriodsImmediate } from "utils/apiRequests";
import { CommoditiesProductI } from "types";
import useContextSync from "hooks/useContextSync";
import DocumentsTab from "components/reusables/DocumentsTab";
import documents from "./Sidebar/documentsConfig";
import Sidebar from "./Sidebar";

export const getLayerID = () => {
  const { selectedLayers } = useContext(MapPageContext);

  return selectedLayers && selectedLayers[0] && selectedLayers[0].comPrdCategory !== undefined
    ? selectedLayers[0].comPrdCategory
    : defaultLayer;
};

const INITIAL_YEAR = 2021;
const geometryClient = new GeoServerClient("countries_prd");

interface mainDataElementI {
  products: CommoditiesProductI[];
  periods: string[];
  defaultPeriod?: string;
  defaultProductKey?: string;
}

const mainData = Object.fromEntries(
  layersDefinitions.map((layer) => {
    return [
      layer.name,
      {
        products: [] as CommoditiesProductI[],
        periods: [] as string[]
      } as mainDataElementI
    ];
  })
);

const getData = (key: string): mainDataElementI => {
  return mainData[key as keyof typeof mainData];
};

//Products are the same independent of layer
getComProductsImmediate("prd").then((res) => {
  Object.keys(mainData).forEach((key) => {
    const element = getData(key);
    //periods depend on key
    //  getPeriodsImmediate("/com/trades-average-periods", key).then((res) => {
    //   element.periods = res;
    //  element.defaultPeriod = element.periods.sort().at(-1);
    //    });
  });
});

export const partialTitle = (context: ProductTreeSelectContextI): string => {
  const { period, product } = context;

  if (product && product != undefined) {
    return " for " + product?.product_name + " in " + period;
  }
  return "";
};

// These functions nodeToId and idToNode are necessary because the TreeView onNodeSelect parameters
// do not provide information about the parent nodes, it passes just the id of the node.
export const nodeToId = (node: CommoditiesProductI) => {
  const { product_code, product_name, commodity_name } = node;
  return `${commodity_name}.${product_code}_${product_name}`;
};

export const idToNode = (id: string): CommoditiesProductI => {
  const [commodity_name, product_data] = id.split(".");
  const [product_code, product_name] = product_data.split("_");

  return {
    product_code: Number(product_code),
    product_name,
    commodity_name
  };
};

const CommoditiesPrdPage = () => {
  const [map, setMap] = useState<Map | null>(null);
  const layer = getLayerID();

  const defaultProductKey = "Soya.236_Soyabeans";
  const defaultPeriod = "2022-2022";

  let element = getData(layer);
  if (!element) element = { products: [], periods: [] } as mainDataElementI;

  const [product, setProduct] = useState<ProductTreeSelectContextI["product"]>(
    defaultProductKey ? idToNode(defaultProductKey) : undefined
  );

  const [period, setPeriod] = useState<ProductTreeSelectContextI["period"]>(defaultPeriod);

  const context = {
    product,
    setProduct,
    period,
    setPeriod,
    defaultProductKey,
    idToNode,
    nodeToId
  };

  useContextSync(context, "production");

  const sidebarTitle = (): string => {
    return partialTitle(context);
  };

  const tabs = [
    { title: <SelectedCountryLayerTitle layerTitle={sidebarTitle()} />, content: <Sidebar /> },
    { title: "Info", content: <DocumentsTab documents={documents()} /> }
  ];

  return (
    <ProductTreeSelectContext.Provider value={context}>
      <MapPage
        map={map}
        initialYear={INITIAL_YEAR}
        layersConfig={layersConfig}
        geometryClient={geometryClient}
        tabs={tabs}
      >
        <StandardLeafletMap setMap={setMap} CustomControlPanel={ControlsPanel} />
      </MapPage>
      //
    </ProductTreeSelectContext.Provider>
  );
};

export default CommoditiesPrdPage;

import { Chart } from "utils/chartsUtils";
import { harvestDataKeys, forestDataKeys, otherDataKeys, harmonizedDataKeys } from "./utils/keysGroups";
import { apiFieldsMappingHarmonizedStatistics } from "./utils/apiFieldsMapping";

const dataKeysByCategory = {
  harvest: harvestDataKeys,
  forest: forestDataKeys,
  other: otherDataKeys,
  harmonized: harmonizedDataKeys
};

const EvolutionCharts = () => {
  return (
    <>
      <Chart
        categories={dataKeysByCategory["harmonized"]}
        title={"Trends in forest cover change for all drivers"}
        apiMapping={apiFieldsMappingHarmonizedStatistics}
      />
      <Chart
        title={"Deforestation"}
        categories={dataKeysByCategory["forest"]}
        apiMapping={apiFieldsMappingHarmonizedStatistics}
        hideLegend={true}
      />

      <Chart
        title={"Forest harvest"}
        categories={dataKeysByCategory["harvest"]}
        apiMapping={apiFieldsMappingHarmonizedStatistics}
        hideLegend={true}
      />
      <Chart
        title={"Disturbances"}
        categories={dataKeysByCategory["other"]}
        stacked={true}
        apiMapping={apiFieldsMappingHarmonizedStatistics}
      />
    </>
  );
};

export default EvolutionCharts;
